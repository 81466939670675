import { App } from '../../../shared/js/register.js';
import { start } from '../../../shared/js/init.js';

import translations from './translations.json';
import '../../../shared/js/phones.js';

document.addEventListener("DOMContentLoaded", function () {
    window.Form = new App({

        noResults: "\u004e\u0065\u0062\u0079\u006c\u0079\u0020\u006e\u0061\u006c\u0065\u007a\u0065\u006e\u0079\u0020\u017e\u00e1\u0064\u006e\u00e9\u0020\u007a\u00e1\u0070\u0061\u0073\u0079",
        messages: {
            required: '\u0056\u0079\u0070\u006c\u0148\u0074\u0065\u0020\u0070\u006f\u006c\u0065',
            phone: {
                incorrect: '\u004e\u0065\u0073\u0070\u0072\u00e1\u0076\u006e\u00e9\u0020\u0074\u0065\u006c\u0065\u0066\u006f\u006e\u006e\u00ed\u0020\u010d\u00ed\u0073\u006c\u006f'
            },
            email: {
                incorrect: '\u004e\u0065\u0073\u0070\u0072\u00e1\u0076\u006e\u00fd\u0020\u0066\u006f\u0072\u006d\u00e1\u0074\u0020\u0061\u0064\u0072\u0065\u0073\u0079',
                symbolNotFound: '\u0041\u0064\u0072\u0065\u0073\u0061\u0020\u006d\u0075\u0073\u00ed\u0020\u006f\u0062\u0073\u0061\u0068\u006f\u0076\u0061\u0074\u0020\u007a\u006e\u0061\u006b\u0020\u201e\u0040\u201c\u002e\u0020\u004e\u0061\u0020\u0061\u0064\u0072\u0065\u0073\u0065\u0020\u0063\u0068\u0079\u0062\u00ed\u0020\u0073\u0079\u006d\u0062\u006f\u006c\u0020\u201e\u0040\u201c',
                addressIsNotFull: '\u0050\u006f\u0020\u007a\u006e\u0061\u006b\u0075\u0020\u201e\u0040\u201c\u0020\u007a\u0061\u0064\u0065\u006a\u0074\u0065\u0020\u010d\u00e1\u0073\u0074\u0020\u0061\u0064\u0072\u0065\u0073\u0079\u002e\u0020\u0041\u0064\u0072\u0065\u0073\u0061\u0020\u006e\u0065\u006e\u00ed\u0020\u00fa\u0070\u006c\u006e\u00e1\u002e'
            },
            password: {
                minLength: '\u004d\u0069\u006e\u0069\u006d\u00e1\u006c\u006e\u00ed\u0020\u0070\u006f\u0076\u006f\u006c\u0065\u006e\u00fd\u0020\u0070\u006f\u010d\u0065\u0074\u0020\u007a\u006e\u0061\u006b\u016f: 6. \u0054\u0065\u010f: %current_length%'
            },
            repeatPassword: {
                discrepancy: '\u005a\u0061\u0064\u0061\u006e\u00e1\u0020\u0068\u0065\u0073\u006c\u0061\u0020\u0073\u0065\u0020\u006e\u0065\u0073\u0068\u006f\u0064\u0075\u006a\u00ed'
            },
            ofertaAgreement: {
                notChecked: '\u0050\u006f\u0074\u0076\u0072\u010f\u0074\u0065\u0020\u0073\u0076\u016f\u006a\u0020\u0073\u006f\u0075\u0068\u006c\u0061\u0073\u0020\u0073\u0020\u0070\u0072\u0061\u0076\u0069\u0064\u006c\u0079\u0020\u0061\u0020\u0070\u006f\u0064\u006d\u00ed\u006e\u006b\u0061\u006d\u0069'
            },
        },

        options: {
            regType: "phone",
            bonusType: "casino",

            tabButtonClass: ".tab",
            formEmailClass: ".form-email",
            formPhoneClass: ".form-phone",
            buttonActionClass: ".button-action",
            emailInputClass: ".email-input",
            passwordInputClass: ".password-input",
            passwordInput2Class: ".password-input2",
            phoneInputClass: ".phone-input",
            emailCountry: ".email-country",
            phonePrefixSelect: ".phone-prefix__select",
            phonePrefixCode: ".phone-prefix__code",
            phoneCurrency: ".phone-currency",
            emailCurrency: ".email-currency",
            phonePrefixFlag: ".phone-prefix__flag",

            emailErrorClass: ".email-error",
            passwordErrorClass: ".password-error",
            password2ErrorClass: ".password2-error",
            phoneErrorClass: ".phone-error",
            ofertaAgreementInputId: "#oferta-agreement",
        },
        
    });

    start({ phones: window.phones, translations: translations });
});